// @ts-nocheck
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { URLS } from "./_config";
import requireNoAuth from "./_common/hoc/reqNoAuth";
import requireAuth from "./_common/hoc/reqAuth";
import reqLayout from "./_common/hoc/layout";
import Landing from "./containers/landing";
import Login from "./containers/auth/login";
import AutoLogin from "./containers/auth/autologin";
import Page404 from "./containers/page-404";
import CacheBuster from "./CacheBuster";
import MyTeam from "./containers/account/myTeam";
import MyLeague from "./containers/account/myLeague";
import GameDashboard from "./containers/game/dashboard";
import GameResults from "./containers/game/results";
import CreateTeam from "./containers/team/createTeam";
import EditTeam from "./containers/team/editTeam";
import MyProfileContainer from "./containers/myprofile";
import FanMagazineContainer from "./containers/fanmagazine/general";
import NewsContainer from "./containers/news";
import WinnerStoryListContainer from "./containers/winnerStory/list";
import WinnerStoryDetailsContainer from "./containers/winnerStory/details";
import RidersContainer from "./containers/riders";
import CmsContainer from "./containers/cms/content/cms";
import SurveyContainer from "./containers/cms/landing-survey/survey";
import ContactUsContainer from "./containers/contactUs";
import LeagueCreateContainer from "./containers/league/leagueCreate";
import LeagueDetailsContainer from "./containers/league/leagueLanding";
import SearchTeamContainer from "./containers/team/searchTeam";
import SearchLeagueTeamContainer from "./containers/league/leagueSearch";
import TeamDetailsContainer from "./containers/team/detailsTeam";
import RodeoResultsContainer from "./containers/game/results/rodeoIndex";
import RoundWiseContainer from "./containers/game/results/roundwiseResultIndex";
import LeagueResultIndex from "./containers/game/results/leagueResultIndex";
import CurcuitIndex from "./containers/game/results/curcuitIndex";
import PrizeResultsContainer from "./containers/game/results/prizeResultsIndex";
import CartContainer from "./containers/cart";
import autoCart from "./containers/auth/autoCart";
function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <Router>
              <Switch>
                <Route exact path={URLS.LANDING} component={reqLayout(Landing)} />
                <Route exact path={URLS.FANMAGAZINE} component={reqLayout(FanMagazineContainer)} />
                <Route exact path={URLS.LOGIN} component={requireNoAuth(Login)} />
                <Route exact path={URLS.MYTEAM} component={requireAuth(MyTeam)} />
                <Route exact path={URLS.MYLEAGUE} component={requireAuth(MyLeague)} />
                <Route exact path={URLS.MYACCOUNT} component={requireAuth(MyProfileContainer)} />
                <Route exact path={URLS.GAMEDASHBOARD} component={reqLayout(GameDashboard)} />
                <Route exact path={URLS.GAMERESULTS} component={reqLayout(GameResults)} />
                <Route exact path={URLS.CREATETEAM} component={requireAuth(CreateTeam)} />
                <Route exact path={URLS.NEWSDETAILS} component={reqLayout(NewsContainer)} />
                <Route exact path={URLS.WINNERSTORIES} component={reqLayout(WinnerStoryListContainer)} />
                <Route exact path={URLS.WINNERSTORY_DETAILS} component={reqLayout(WinnerStoryDetailsContainer)} />
                <Route exact path={URLS.RIDERS} component={reqLayout(RidersContainer)} />
                <Route exact path={URLS.CMS} component={reqLayout(CmsContainer)} />
                <Route exact path={URLS.CONTACTUS} component={reqLayout(ContactUsContainer)} />
                <Route exact path={URLS.LEAGUECREATE} component={requireAuth(LeagueCreateContainer)} />
                <Route exact path={URLS.LEAGUEDETAILS} component={requireAuth(LeagueDetailsContainer)} />
                <Route exact path={URLS.SEARCHTEAM} component={reqLayout(SearchTeamContainer)} />
                <Route exact path={URLS.SEARCHLEAGUETEAM} component={reqLayout(SearchLeagueTeamContainer)} />
                <Route exact path={URLS.EDITTEAM} component={requireAuth(EditTeam)} />
                <Route exact path={URLS.TEAMDETAILS} component={reqLayout(TeamDetailsContainer)} />
                <Route exact path={URLS.RODEORESULTS} component={reqLayout(RodeoResultsContainer)} />
                <Route exact path={URLS.ROUNDWISERESULTS} component={reqLayout(RoundWiseContainer)} />
                <Route exact path={URLS.LEAGUERESULTS} component={reqLayout(LeagueResultIndex)} />
                <Route exact path={URLS.CURCUITRESULTS} component={reqLayout(CurcuitIndex)} />
                <Route exact path={URLS.PRIZERESULTS} component={reqLayout(PrizeResultsContainer)} />
                <Route exact path={URLS.AUTOLOGIN} component={reqLayout(AutoLogin)} />
                <Route exact path={URLS.CARTPAGE} component={requireAuth(CartContainer)} />
                <Route exact path={URLS.LANDINGSURVEY} component={reqLayout(SurveyContainer)} />
                <Route exact path={URLS.AUTOCARTOPEN} component={reqLayout(autoCart)} />
                <Route path="*" component={reqLayout(Page404)} />
              </Switch>
            </Router>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

export default App;
