import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL } from "../_config";
import { CallApi } from "./api/callApi";

export function* getTeamDraftData(action: any): any {
  try {
    //const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TEAM.LOAD_DRAFT_TEAM_DATA);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.LOAD_DRAFT_TEAM_DATA,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* saveTeamDraftData(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.SAVE_DRAFT_TEAM_DATA, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // yield put({
      //   type: ACTIONS.TEAM.LOAD_DRAFT_TEAM_DATA,
      //   payload: resp.data.data,
      // });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getEventList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.GET_EVENT_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.GET_EVENT_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInsuranceList(action: any): any {
  try {
    const gameId = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TEAM.GET_INSURANCE_LIST + gameId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.GET_INSURANCE_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getPrizeList(action: any): any {
  try {
    const gameId = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TEAM.GET_PRIZE_LIST + gameId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.GET_PRIZE_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getFrontendCharity(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      data.leagueID
        ? API_URL.TEAM.GET_FRONTEND_LEAGUE + data.gameId + "/" + data.leagueID
        : API_URL.TEAM.GET_FRONTEND_LEAGUE + data.gameId
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.GET_FRONTEND_LEAGUE,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* checkApplyPromoCode(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.CHECK_PROMO_CODE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // yield put({
      //     type: ACTIONS.TEAM.LOAD_DRAFT_TEAM_DATA,
      //     payload: resp.data.data,
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getContestantAILIst(action: any): any {
  try {
    const gameId = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TEAM.GET_CONTESTANT_AI + gameId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.GET_CONTESTANT_AI,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getSearchContestantAILIst(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.SEARCH_CONTESTANT_AI, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.SEARCH_AI_SELECT_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getCheckContestantAI(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.CHECK_CONTESTANT_AI, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* callMakePayment(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PAYMENT.MAKE_PAYMENT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* callBulkMakePayment(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PAYMENT.MAKE_BULK_PAYMENT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* callMyTeamList(action: any): any {
  try {
    const gameID = action.payload;
    const resp = yield call(CallApi.GET, gameID ? API_URL.TEAM.MY_TEAM_LIST + gameID : API_URL.TEAM.MY_TEAM_LIST);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.MY_TEAM_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* searchTeam(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.SEARCH_TEAM, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteTeam(action: any): any {
  try {
    const teamId = action.payload;
    const resp = yield call(CallApi.DELETE, API_URL.TEAM.DELETE_TEAM + teamId, teamId, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* loadTeamDataByID(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TEAM.LOAD_TEAM_DETAILS + data.teamId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.LOAD_TEAM_DETAILS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* callUpdateTeam(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.UPDATE_TEAM + data.team_id, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getPerformanceList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TEAM.GET_PERFORMANCE_LIST + data.gameId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.GET_PERFORMANCE_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getInsuranceApplicable(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.GET_INSURANCE_APPLICABLE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.GET_INSURANCE_APPLICABLE,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInsuranceApply(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.INSURANCE_APPLY, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.INSURANCE_APPLY,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getRevertInsurance(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.REVERT_INSURANCE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.INSURANCE_APPLY,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getCartItems(action: any): any {
  try {
    const gameId = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TEAM.CART_ITEMS + (gameId ? gameId : ""));
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.CART_ITEMS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
export function* updateTeamWinning(action: any): any {
  try {
    const params = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TEAM.UPDATE_TEAM_WINNINGS, params, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getTeamListForLeagueAssign(action: any): any {
  try {
    const leagueId = action.payload.leagueId;
    const resp = yield call(CallApi.GET, API_URL.TEAM.TEAMLISTFORLEAGUEASSIGN + (leagueId ? leagueId : ""));
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.TEAM.TEAMLISTFORLEAGUEASSIGN,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
export function* checkTeamAddForLeague(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_URL.TEAM.CHECKTEAMADDFORLEAGUE + data.teamId + "/" + data.leagueIdForAssign,
      {},
      true
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
export function* updateTeamForLeague(action: any): any {
  try {
    const params = action.payload;
    console.log(params);
    const resp = yield call(CallApi.POST, API_URL.TEAM.UPDATETEAMFORLEAGUE, params, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
